<template>
  <b-row class="card-row py-3" align-v="center" align-h="center">
    <b-col class="">{{ card.userName }}</b-col>
    <b-col sm="3" class="d-flex ">
      <button
        @click="isEditing = !isEditing"
        class="border-0 mr-1 align-self-center"
      >
        <font-awesome-icon class="" icon="edit" />
      </button>
      <div class="d-flex" v-if="isEditing">
        <input class="form-control" type="email" v-model="newEmail" />
        <button @click="updateEmail()" class="border-0 ml-1 align-self-center">
          <font-awesome-icon class="" :style="{ color: 'green' }" icon="check">
          </font-awesome-icon>
        </button>

        <button
          class="border-0 ml-1 align-self-center"
          @click="isEditing = false"
        >
          <font-awesome-icon class="" :style="{ color: 'red' }" icon="undo">
          </font-awesome-icon>
        </button>
      </div>
      <span v-else>{{ cardEmail.value ? cardEmail.value : cardEmail }}</span>
    </b-col>
    <b-col sm="2" class=""> {{ card.expirationTimestamp }}</b-col>
    <b-col  class="ml-3"> {{ card.orderId }}</b-col>
    <b-col  class=""> {{ card.referenceOrderID }}</b-col>
    <b-col  class="">{{ card.status }}</b-col>
    <b-col class="d-flex no-wrap">
      <button
        type="button"
        class="btn btn-secondary btn-sm"
        v-on:click="cancel()"
        :disabled="!isCardCancellable(card.status)"
      >
        cancel
      </button>
      <button
        type="button"
        class="btn btn-secondary btn-sm ml-2"
        @click="resendEmail()"
        :disabled="!isCardEmaileable(card.status)"
      >
        email
      </button>
      <b-button
        type="button"
        class="btn btn-secondary btn-sm ml-2"
        target="_blank"
        :to="{ name: 'UserViewCard', params: { code: card.code } }"
      >
        see
      </b-button>
    </b-col>
    <b-modal v-model="modalShow">{{ modalMessage }}</b-modal>
  </b-row>
</template>
 
<script>
import { ref, reactive } from "@vue/composition-api";
import { changeEmail, emailCardOwner, cancelCard } from "../api/admin";
import Axios from "axios";
export default {
  props: ["card"],
  setup(props) {
    const cardEmail = ref(props.card.userEmail);

    const modalShow = ref(false);
    const modalMessage = ref("");

    const isEditing = ref(false); // used for tracking if the email of the card is being edited
    const newEmail = ref(props.card.userEmail); //setting to the original email so the input placeholder is the original

    const isCardCancellable = (cardStatus) => {
      return cardStatus === "PENDING";
    };

    const resendEmail = async () => {
      try {
        const resp = await emailCardOwner(props.card.code);
        if (resp.status != 200) {
          modalMessage.value = `Did not receive an OK response. Received: ${resp.status}`;
        } else {
          modalMessage.value = "Email sent";
        }
      } catch (e) {
        console.error(e);
        modalMessage.value = `Error sending email: ${e}`;
      }
      modalShow.value = true;
    };

    const updateEmail = async () => {
      try {
        const resp = await changeEmail(
          props.card.code,
          props.card.userEmail,
          newEmail.value
        );
        cardEmail.value = newEmail;
      } catch (e) {
        console.error(e);
      }

      isEditing.value = false;
    };

    function isCardEmaileable(cardStatus) {
      return cardStatus === "PENDING" || cardStatus === "ACTIVE";
    }

    const cancel = async () => {
      try {
        const card = await cancelCard(props.card.code);

        if (card) {
          modalMessage.value = "Card Cancelled";
        } else {
          modalMessage.value = `Something went wrong cancelling the card.`;
        }
      } catch (e) {
        modalMessage.value = `Error sending email: ${e}`;
      }
      modalShow.value = true;
    };
    return {
      isCardCancellable,
      isCardEmaileable,
      isEditing,
      newEmail,
      updateEmail,
      cardEmail,
      resendEmail,
      modalShow,
      modalMessage,
      cancel,
    };
  },
};
</script>